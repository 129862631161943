import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import Intercom from "react-intercom"
import { useDispatch, useSelector } from "react-redux"

import { APP_INTERCOM } from '../../../@common/environments/environment'
import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"
import { ModalPoliciesAndTerms } from "../../Modals/ModalPoliciesAndTerms/ModalPoliciesAndTerms"
import { getIp } from "../../../@common/utils/getIpApi"
import { language as LanguageActions } from "../../../services/Language/LanguageActions"

const LayoutPublic = ({ children, handlerInFooter, section, fix }) => {
  const dispatch = useDispatch()
  const [refFooter, inFotter] = useInView({ threshold: 0 })
  const { modalPolicyPrivacity } = useSelector(state => state.modals)
  const { countrieCurrent, languageCurrent } = useSelector(state => state.language)
  const [pageTransition, setPageTransition] = useState()

  useEffect(() => {
    let mounted = true
    if (mounted && handlerInFooter) handlerInFooter(inFotter)

    return () => mounted = false
  }, [inFotter])

  useEffect(() => {
    if (languageCurrent) {
      setPageTransition(true)

      setTimeout(() => {
        updatePreviousPage()
        setPageTransition(false)
      }, 3000)
    }
  }, [])

  useEffect(() => {
    let mounted = true
    if (mounted && location.pathname === '/' && languageCurrent && countrieCurrent)
      detectedCountrieNavigator()

    return () => mounted = false
  }, [languageCurrent, countrieCurrent])

  const detectedCountrieNavigator = async () => {
    let browserData = await getIp
    //browserData.data.country = 'BR'
    if (countrieCurrent !== browserData.data.country && browserData.data.country !== 'CO')
      dispatch(LanguageActions.redirectNewLng(languageCurrent, browserData.data.country))
  }

  const updatePreviousPage = () => {
    //Se le agregá 1 segundo para darle tiempo a la animación de desaparecer
    setTimeout(() => {
      const previousPage = localStorage.getItem('previousPage')
      const history = previousPage?.split(',')

      if (previousPage)
        localStorage.setItem('previousPage', history.current + ',' + location.pathname)
      else
        localStorage.setItem('previousPage', location.pathname)
    }, 1000)
  }

  const animationTransition = () => {
    if (typeof window !== "undefined") {
      const previousPage = localStorage.getItem('previousPage')

      const history = previousPage?.split(',')

      //Izquierda a derecha = Nueva página
      //Derecha a izquierda = Página anterior
      if (pageTransition)
        return history?.prev === location.pathname ? 'animate__slideInRight' : 'animate__slideInLeft'
      else if (pageTransition === false)
        return history?.prev === location.pathname ? 'animate__slideOutLeft' : 'animate__slideOutRight'
    }
    return 'page-hidden'
  }

  return (
    <>
      { modalPolicyPrivacity && <ModalPoliciesAndTerms />}
      <div className={
        `page-transition ${animationTransition()} animate__animated `
      }>
        <img
          className="img-loader-animated"
          src={require('../../../assets/images/loader-trancition.gif')}
          alt="loader-liftit"
          style={{ width: 150 }}
        />
      </div>
      <div className={
        `page-transition page-transition--two ${animationTransition()} animate__animated`
      }></div>
      <div className={
        `page-transition page-transition--three ${animationTransition()} animate__animated`
      }></div>
      <Header name={fix} section={section} />
      <main className="animate__animated animate__fadeIn animate__delay-2s">{children}</main>
      <Intercom appID={APP_INTERCOM} />
      <Footer refFooter={refFooter} />
    </>
  )
}

LayoutPublic.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutPublic

