import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col } from "antd"

import { navigatePage } from '../../@common/utils/navigate'
import { languages } from "../../@common/constansts/languages"
import { language as LanguageActions } from "../../services/Language/LanguageActions"
import { useContenfulFooter } from "../../contenful/querys/Footer/Footer"
import { useContenfulCountrys } from "../../contenful/querys/Countrys"

const items = [
  "generator",
  "operator",
  "carrier",
  null,
  null,
  //"technologies",
  //"abouts",
  "resources",
]

const Footer = ({ refFooter }) => {
  const protocol =
    typeof window !== "undefined" ? window.location.protocol : "https:"
  const dispatch = useDispatch()

  const { languageISO639, countrieCurrent, languageCurrent } = useSelector(
    state => state.language
  )
  const contenfulCountrys = useContenfulCountrys()
  const contenfulFooter = useContenfulFooter(languageISO639)

  const handlerChangeLng = value => {
    dispatch(LanguageActions.redirectNewLng(value, countrieCurrent))
  }

  const handlerChangeCountrie = _object => {
    dispatch(LanguageActions.redirectNewLng(languageCurrent, _object.key))
  }

  if (!contenfulFooter) return null

  return (
    <div ref={refFooter}>
      <footer>
        <div className="footer__container">
          <Row justify="space-between">
            <Col
              xs={24}
              sm={24}
              md={2}
              lg={2}
              xl={2}
              xxl={2}
              className="select--desktop"
            >
              <div className="select--desktop__panel">
                <div className="select--desktop__panelCountries">
                  {contenfulCountrys?.map(country => (
                    <div
                      key={country.key}
                      role="button"
                      tabIndex="0"
                      className={`select--desktop__panelCountries${countrieCurrent === country.key
                          ? "--selected"
                          : "__countrie"
                        }`}
                      onKeyDown={() => handlerChangeCountrie(country)}
                      onClick={() => handlerChangeCountrie(country)}
                    >
                      <figure>
                        {country.flag?.file && (
                          <img
                            src={`${protocol}${country.flag.file.url}`}
                            alt={country.flag.file.fileName}
                          />
                        )}
                      </figure>
                      <p>{country.name}</p>
                    </div>
                  ))}
                </div>
                <div className="select--desktop__panel-language">
                  <ul>
                    {languages.map((lng, index) => (
                      <li key={index}>
                        <div
                          className={languageCurrent === lng.key ? 'select--select-language' : ''}
                          onClick={() => handlerChangeLng(lng.key)}
                        >
                          {lng.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
            {items.map((item, index) => item ?
              (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={2}
                  xl={2}
                  xxl={2}
                  key={`i-${index}`}
                >
                  <h3>
                    {contenfulFooter.titlesFooter &&
                      contenfulFooter.titlesFooter[index]}
                  </h3>
                  {contenfulFooter[item]?.map((_item, i) => (
                    <a onClick={() => navigatePage(_item.link.link)} key={i}>
                      <p>{_item.label}</p>
                    </a>
                  ))}
                </Col>
              ) : null
            )}
            <Col xl={4} sm={24} xs={24} className="select--mobile">
              <div className="select--mobile__panel">
                <div className="select--mobile__panelCountries">
                  {contenfulCountrys?.map(country => (
                    <div
                      key={country.key}
                      role="button"
                      tabIndex="0"
                      className={`select--mobile__panelCountries${countrieCurrent === country.key
                          ? "--selected"
                          : "__countrie"
                        }`}
                      onKeyDown={() => handlerChangeCountrie(country)}
                      onClick={() => handlerChangeCountrie(country)}
                    >
                      <figure>
                        {country.flag?.file && (
                          <img
                            src={`${protocol}${country.flag.file.url}`}
                            alt={country.flag.file.fileName}
                          />
                        )}
                      </figure>
                      <p>{country.name}</p>
                    </div>
                  ))}
                </div>
                <div className="select--mobile__panel-language">
                  <ul>
                    {languages.map((lng, index) => (
                      <li key={index}>
                        <div
                          className={languageCurrent === lng.key ? 'select--select-language' : ''}
                          onClick={() => handlerChangeLng(lng.key)}
                        >
                          {lng.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
            <Col xs={24}>
              <p className="organization">© Liftit S.A.S</p>
            </Col>
          </Row>
        </div>
      </footer>
    </div>
  )
}
export default Footer
