import { useStaticQuery, graphql } from "gatsby"

export const useContenfulFooter = lng => {
  const {
    allContentfulFooter: { nodes: contenfulFooter },
  } = useStaticQuery(graphql`
    {
      allContentfulFooter {
        nodes {
          titlesFooter
          language {
            language
          }
          carrier {
            label
            link {
              link
            }
          }
          abouts {
            label
            link {
              link
            }
          }
          generator {
            label
            link {
              link
            }
          }
          operator {
            label
            link {
              link
            }
          }
          resources {
            label
            link {
              link
            }
          }
          technologies {
            label
            link {
              link
            }
          }
        }
      }
    }
  `)

  const transform = {}

  contenfulFooter.forEach(env => {
    if (env.language.language === lng) {
      Object.keys(env).forEach(key => {
        transform[key] = env[key]
      })
    }
  })
  return transform
}
