import React from "react"
import { Menu } from "antd"
import { useSelector } from "react-redux"

import { navigatePage } from "../../../../@common/utils/navigate"
import { useContenfulMenu } from "../../../../contenful/querys/Header/Menu"

const MenuPrimary = () => {
  const { languageISO639 } = useSelector(state => state.language)
  const contenfulMenu = useContenfulMenu(languageISO639)

  return (
    <Menu mode="horizontal" className="menu-primary">
      <Menu.Item
        className="menu-primary__item"
        onClick={() => navigatePage("/generators")}
      >
        {contenfulMenu.itemGenerators}
      </Menu.Item>
      <Menu.Item
        className="menu-primary__item"
        onClick={() => navigatePage("/operators")}
      >
        {contenfulMenu.itemOperators}
      </Menu.Item>
      <Menu.Item 
        className="menu-primary__item"
        onClick={() => navigatePage("/lifters")}
      >
        {contenfulMenu.itemConveyor}
      </Menu.Item>
    </Menu>
  )
}

export default MenuPrimary
