import { navigate } from "gatsby"

const pathWithGuion = ['home-demo', 'contact-us', 'lifters-thank-you', 'revolutionizes-deliveries']

export const navigatePage = page => {

  let pathLanguage = window.location.pathname.split("-")

  //     /pt-CO/view || /pt-CO/view/
  if(pathLanguage.length > 1 && window.location.pathname.split('/').length > 2){

    let pageExist = false

    pathWithGuion.forEach(path => {
      const [pathInit, pathFinish] = pathLanguage
      const [_pathInit, _pathFinish] = path.split('-')

      if(pathInit.substr(1) === _pathInit && pathFinish.substr(0, pathFinish.length - 1) === _pathFinish)
        pageExist = true
    })

    if(!pageExist){
      const lng = pathLanguage[0].substr(pathLanguage[0].length - 2)
      const alpha2code = pathLanguage[1].substring(0, 2)
      navigate(`/${lng}-${alpha2code}${page}`)
    } else {
      navigate(page)
    }
  } else
    navigate(page)
}
