import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { useTranslation } from "react-i18next"

import iconFace from "../../../../assets/images/social-networks/icon-facebook.svg"
import iconInst from "../../../../assets/images/social-networks/icon-instagram.svg"
import iconLink from "../../../../assets/images/social-networks/icon-linkedin.svg"
import { useContenfulSocialNetworks } from "../../../../contenful/querys/SocialNetwork"
import { useContenfulMenu } from "../../../../contenful/querys/Header/Menu"
import { useContenfulFooter } from "../../../../contenful/querys/Footer/Footer"
import { navigatePage } from "../../../../@common/utils/navigate"

const itemsKeysContentful = {
  itemGenerators: 'generator',
  itemOperators: 'operator',
  itemConveyor: 'carrier'
}

const MenuMobile = () => {
  const { t } = useTranslation()
  const { countrieCurrent, languageISO639 } = useSelector(state => state.language)

  const socialNetworks = useContenfulSocialNetworks(countrieCurrent?.key)
  const contenfulFooter = useContenfulFooter(languageISO639)
  const contenfulMenu = useContenfulMenu(languageISO639)

  const items = [
    /*{
      label: t('general.developers'),
      link: '/developers'
    },*/
    /*{
      label: t('general.resources'),
      link: '/resources'
    },*/
    /*{
      label: t('general.aboutus'),
      link: '/developers'
    }*/
  ]

  console.log(contenfulFooter);
  return (
    <div className="menu-mobile">
      <div className="menu-mobile__top" />
      <div className="menu-mobile__items has-margin-top-40 has-margin-bottom-20">
        {items.map(item => (
          <a key={item.link} onClick={() => navigatePage(item.link)}>
            {item.label}
          </a>
        ))}
      </div>
      <div className="menu-mobile__accordion">
        <Accordion allowZeroExpanded="true">
          {Object.keys(itemsKeysContentful).map((key, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>{contenfulMenu[key]}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {contenfulFooter[itemsKeysContentful[key]].map((option, i) => (
                  <a
                    onClick={() => navigatePage(option.link.link)}
                    key={i}
                    className={`menu-mobile__accordionLink ${option.isDisabled
                        ? "menu-mobile__accordionLink--disabled"
                        : ""
                      }`}
                  >
                    {option.label}
                  </a>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <div className="menu-mobile__redes">
        <a target="blank" href={socialNetworks.facebook_url}>
          <img src={iconFace} alt="iconface" />
        </a>
        <a target="blank" href={socialNetworks.instagram_url}>
          <img src={iconInst} alt="iconinstagram" />
        </a>
        <a target="blank" href={socialNetworks.linkdin_url}>
          <img src={iconLink} alt="iconlinkedin" />
        </a>
      </div>
    </div>
  )
}

export default MenuMobile
