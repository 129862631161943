import { useStaticQuery, graphql } from "gatsby"

export const useContenfulMenu = lng => {
  const {
    allContentfulMenu: { nodes: contenfulMenu },
  } = useStaticQuery(graphql`
    {
      allContentfulMenu {
        nodes {
          itemConveyor
          itemGenerators
          itemOperators
          language {
            language
          }
        }
      }
    }
  `)

  const transform = {}

  contenfulMenu.forEach(env => {
    if (env.language?.language === lng) {
      Object.keys(env).forEach(key => {
        transform[key] = env[key]
      })
    }
  })

  return transform
}
