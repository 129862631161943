import { useStaticQuery, graphql } from "gatsby"

export const useContenfulCountrys = lng => {
  const {
    allContentfulPaises: { nodes: contenfulCountrys },
  } = useStaticQuery(graphql`
    {
      allContentfulPaises {
        nodes {
          key
          name
          language {
            language
          }
          flag {
            file {
              url
              fileName
            }
          }
        }
      }
    }
  `)

  const transform = []

  contenfulCountrys.forEach(item => {
    if (!lng || item.language?.language === lng) transform.push(item)
  })

  return transform
}
