import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Modal } from 'antd';
import { useTranslation } from "react-i18next"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { useContentfulGeneral } from "../../../contenful/querys/General";
import { modals as ModalsActions } from "../../../services/Modals/ModalsActions";

export const ModalPoliciesAndTerms = () => {

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [visible, setVisible] = useState(true)
	const { languageISO639 } = useSelector(state => state.language)
	const contentfulGeneral = useContentfulGeneral(languageISO639)
	
	const handleOk = e => {
		setVisible(false)
		dispatch(ModalsActions.setModals('modalPolicyPrivacity', false))
	}

	const handleCancel = e => {
		setVisible(false)
		dispatch(ModalsActions.setModals('modalPolicyPrivacity', false))
	}

	return (
		<div className="ModalPoliciesAndTerms">
			<Modal
				className="ModalPoliciesAndTerms--modal"
				title={t('general.title_politics_privacity')}
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				{documentToReactComponents(contentfulGeneral.terms_and_conditions.json)}
			</Modal>
		</div>
	);
}