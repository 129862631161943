import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col } from "antd"
import { useTranslation } from "react-i18next"

import { languages } from "../../../../@common/constansts/languages"
import { language as LanguageActions } from "../../../../services/Language/LanguageActions"
import iconFace from "../../../../assets/images/social-networks/icon-facebook.svg"
import iconInst from "../../../../assets/images/social-networks/icon-instagram.svg"
import iconLink from "../../../../assets/images/social-networks/icon-linkedin.svg"
import { useContenfulCountrys } from "../../../../contenful/querys/Countrys"
import { useContenfulSocialNetworks } from "../../../../contenful/querys/SocialNetwork"

const TopBar = () => {
  const protocol =
    typeof window !== "undefined" ? window.location.protocol : "https:"
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const contenfulCountrys = useContenfulCountrys()
  const {
    languageISO639,
    languageDefault,
    countrieCurrent,
    languageCurrent,
  } = useSelector(state => state.language)
  const socialNetworks = useContenfulSocialNetworks(countrieCurrent || "CO")
  const [openSelect, setOpenSelect] = useState(false)
  const [countryCurrentMetada, setCountryCurrentMetada] = useState()

  useEffect(() => {
    if (contenfulCountrys && !languageCurrent) {
      // language default
      if (languageISO639 === languageDefault) {
        const selectCO = contenfulCountrys.find(item => item.key === "CO")
        const [lng] = languageDefault.split("-")
        dispatch(LanguageActions.setLanguageCurrent(lng))
        dispatch(LanguageActions.setCountrieCurrent(selectCO.key))
        // other language
      } else {
        const [lng, countrie] = languageISO639.split("-")
        const selectCountrie = contenfulCountrys.find(
          item => item.key === countrie
        )
        dispatch(LanguageActions.setLanguageCurrent(lng))
        dispatch(LanguageActions.setCountrieCurrent(selectCountrie.key))
      }
    }
  }, [contenfulCountrys])

  useEffect(() => {
    if (countrieCurrent) {
      const isExist = contenfulCountrys.find(
        item => item.key === countrieCurrent
      )
      setCountryCurrentMetada(isExist)
    }
  }, [countrieCurrent])

  const handlerChangeLng = value => {
    dispatch(LanguageActions.redirectNewLng(value, countrieCurrent))
    setOpenSelect(false)
  }

  const handlerChangeCountrie = _object => {
    dispatch(LanguageActions.redirectNewLng(languageCurrent, _object.key))
    setOpenSelect(false)
  }

  return (
    <div className="top-bar">
      <div className="container">
        <Row>
          <Col span={12}>
            <figure className="top-bar__redes">
              <a target="blank" href={socialNetworks?.facebook_url}>
                <img src={iconFace} alt="facebook" />
              </a>
              <a target="blank" href={socialNetworks?.instagram_url}>
                <img src={iconInst} alt="instagram" />
              </a>
              <a target="blank" href={socialNetworks?.linkdin_url}>
                <img src={iconLink} alt="linkedin" />
              </a>
            </figure>
          </Col>
          <Col span={12}>
            <div className="top-bar__nav">
              <nav className="top-bar__navBar">
                <ul>
                  {/*<li>
                    <a href="/developers">{t('general.developers')}</a>
                  </li>*/}
                  {/*<li>
                    <a href="/">{t('general.aboutus')}</a>
                  </li>*/}
                  {/*<li>
                    <a href="/resources">{t('general.resources')}</a>
                  </li>*/}
                </ul>
              </nav>
              <div className="select-language">
                <div
                  className="select-language__btn"
                  onClick={() => setOpenSelect(!openSelect)}
                >
                  <figure className="select-language__btnIcon">
                    {countryCurrentMetada?.flag?.file && (
                      <img
                        src={`${protocol}${countryCurrentMetada?.flag?.file?.url}`}
                        alt={countryCurrentMetada?.flag?.file?.fileName}
                      />
                    )}
                  </figure>
                  <span className="select-language__btnPrefix">
                    {languageCurrent?.toUpperCase()}
                  </span>
                </div>
                <div
                  className={`select-language__panel ${
                    openSelect
                      ? "select-language__panelShow"
                      : "select-language__panelHide"
                  }`}
                >
                  <div className="select-language__panelCountries">
                    <p className="select-language__panelCountries__title">
                      {t("general.countries")}
                    </p>
                    {contenfulCountrys?.map(country => (
                      <div
                        key={country.key}
                        className="select-language__panelCountries__countrie"
                        onClick={() => handlerChangeCountrie(country)}
                      >
                        <figure>
                          {country.flag?.file && (
                            <img
                              src={`${protocol}${country.flag.file.url}`}
                              alt={country.flag.file.fileName}
                            />
                          )}
                        </figure>
                        <p>{country.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className="select-language__panelIdioma">
                    <p className="select-language__panelIdioma__title">
                      {t("general.languages")}
                    </p>
                    <ul>
                      {languages.map((lng, index) => (
                        <li key={index}>
                          <div onClick={() => handlerChangeLng(lng.key)}>
                            {lng.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TopBar
