import React, { useState } from "react"
import { Layout } from "antd"

import { navigatePage } from "../../@common/utils/navigate"
import TopBar from "./components/TopBar/TopBar"
import Logo from "../../assets/images/logo.svg"
import Menu from "./components/Menu/Menu"
import MenuMobile from "./components/MenuMobile/MenuMobile"
import logo_dark from "../../assets/images/logo.png"

const { Header: HeaderAntD } = Layout

const Header = ({ section, name }) => {
  const [activeMenu, setActiveMenu] = useState(false)

  return (
    <Layout className="layout">
      <TopBar />
      <HeaderAntD className={`${name}`}>
        <div className="container">
          <div
            className={`section-header ${
              section === "section_ligth" && "section_ligth"
            }`}
          >
            <div className="section-header__start">
              <figure onClick={() => navigatePage("/")} className="section-header__logo">
                {section === "section_ligth" ? (
                  <img src={logo_dark} alt="logo" />
                ) : (
                  <img src={Logo} alt="logo" />
                )}
              </figure>
            </div>
            <div className="section-header__end">
              <Menu />
            </div>
            <div
              className={`menu-hamburger ${activeMenu && "is-active"}`}
              onClick={() => setActiveMenu(!activeMenu)}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
        <div
          className={`menu-mobile-is-close ${
            activeMenu ? "is-show" : "is-hide"
          }`}
        >
          <MenuMobile />
        </div>
      </HeaderAntD>
    </Layout>
  )
}

export default Header
