import { useStaticQuery, graphql } from "gatsby"

export const useContentfulGeneral = lng => {
  const {
    allContentfulGeneral: { nodes: contentfulGeneral },
  } = useStaticQuery(graphql`
    {
      allContentfulGeneral {
        nodes {
          language {
            language
          }
          follous_note
          followus_description {
            followus_description
          }
          document
          type_documents {
            ...FieldFragment
          }
          types_licenses {
            ...FieldFragment
          }
          privacy_policies
          terms_conditions
          play_store
          terms_and_conditions {
            json
          }
        }
      }
    }
  `)

  const transform = {}

  contentfulGeneral.forEach(env => {
    if (env.language.language === lng) {
      Object.keys(env).forEach(key => {
        transform[key] = env[key]
      })
    }
  })
  return transform
}
