import apisauce from 'apisauce'

export const getIpApi = () => {
  const protocol = window.location.protocol
  return fetch(`${protocol}//ip-api.com/json`).then(data => data.json())
}

const create = async (baseURL) => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000,
    headers: {},
  })

  return await api.get(`json`)
}

// let's return back our create method as the default.
export const getIp = create('https://ipapi.co/')
